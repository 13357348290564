import "./App.css";
import React, { useRef, useEffect, useContext } from "react";
import Advantages from "./components/advantages/Advantages";
import AllInOne from "./components/all-in-one/AllInOne";
import Progress from "./components/progress/Progress";
import Banner from "./components/banner/Banner";
import Footer from "./components/footer/Footer";
import Location from "./components/location/Location";
import ModalHeader from "./components/modalHeader/ModalHeader";
import ModalForm from "./components/modalForm/ModalForm";
import Widget from "./components/widget/Widget";
import { AppContext } from "./components/context/Context";
import Success from "./components/form/Success";
import Panorama from "./components/panorama/Panorama";
// import Snowfall from "react-snowfall";

function App() {
    const { isFormOpen, setIsFormOpen, is404, setIs404, isSubmitted } = useContext(AppContext);
    return (
        <div className="App">
            {/* <Snowfall color="white" style={{ zIndex: 30, position: "fixed", top: 0 }} snowflakeCount={40} speed={[0.5, 1]} wind={[-0.5, 2]} radius={[0.5, 2.5]} /> */}
            <ModalHeader />
            <Banner />
            <Advantages />
            <Location />
            <AllInOne />
            <Panorama />
            <Progress />
            {/* <Widget /> */}
            <Footer />
            <ModalForm isOpen={isFormOpen} setIsOpen={setIsFormOpen} formId="pop-up" />
            <Success />
        </div>
    );
}

export default App;
