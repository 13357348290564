import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./Percentage.css";

const Percentage = () => {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (percentage < 70) {
                setPercentage(percentage + 1);
            }
        }, 50);
    }, [percentage]);

    return (
        <div>
            <div>
                <CircularProgressbar value={percentage} text={`${percentage}%`} />
            </div>
        </div>
    );
};

export default Percentage;
